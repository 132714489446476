import request from '../../core/request';
import { openNotification } from '../../utils/Notification';
import {
  fetchCoursesRequest,
  fetchCoursesSuccess,
  fetchCoursesFailure,
  createCourseRequest,
  createCourseSuccess,
  createCourseFailure,
  updateCourseRequest,
  updateCourseSuccess,
  updateCourseFailure,
  deleteCourseRequest,
  deleteCourseSuccess,
  deleteCourseFailure,
  searchCoursesRequest,
  searchCoursesSuccess,
  searchCoursesFailure
} from './courses.actions';

const adminId = localStorage.getItem('admin');
// Function to handle fetching courses
export const fetchCourses = (AdminId) => {
  return (dispatch) => {
    dispatch(fetchCoursesRequest());
    return request({
      method: 'GET',
      url: `/courses/?adminId=${AdminId || adminId}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(fetchCoursesSuccess(response.data));
        } else if (response?.status === 404 || response?.status === 204) {
          dispatch(fetchCoursesSuccess([]));
        } else {
          dispatch(fetchCoursesFailure('Fetching courses failed'));
          openNotification({
            type: 'error',
            title: 'Fetching Courses Failed',
            description: 'Fetching courses failed',
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          dispatch(fetchCoursesSuccess([]));
        } else {
          dispatch(fetchCoursesFailure('An error occurred while fetching courses'));
          openNotification({
            type: 'error',
            title: 'Fetching Courses Error',
            description: `An error occurred while fetching courses: ${error.message}`,
          });
        }
      });
  };
};

// Function to handle creating a course
export const createCourse = (courseData) => {
  return (dispatch) => {
    dispatch(createCourseRequest(courseData));
    return request({
      method: 'POST',
      url: `/courses/?adminId=${adminId}`,
      data: courseData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(createCourseSuccess(response.data));
        } else {
          dispatch(createCourseFailure('Creating course failed'));
          openNotification({
            type: 'error',
            title: 'Creating Course Failed',
            description: 'Creating course failed',
          });
        }
      })
      .catch((error) => {
        dispatch(createCourseFailure('An error occurred while creating the course'));
        openNotification({
          type: 'error',
          title: 'Creating Course Error',
          description: `An error occurred while creating the course: ${error.message}`,
        });
      });
  };
};

// Function to handle updating a course
export const updateCourse = (courseData) => {
  return (dispatch) => {
    dispatch(updateCourseRequest(courseData));
    return request({
      method: 'PUT',
      url: `/courses/${courseData.id}`,
      data: courseData,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(updateCourseSuccess(response.data));
        } else {
          dispatch(updateCourseFailure('Updating course failed'));
          openNotification({
            type: 'error',
            title: 'Updating Course Failed',
            description: 'Updating course failed',
          });
        }
      })
      .catch((error) => {
        dispatch(updateCourseFailure('An error occurred while updating the course'));
        openNotification({
          type: 'error',
          title: 'Updating Course Error',
          description: `An error occurred while updating the course: ${error.message}`,
        });
      });
  };
};

// Function to handle deleting a course
export const deleteCourse = (courseId) => {
  return (dispatch) => {
    dispatch(deleteCourseRequest(courseId));
    return request({
      method: 'DELETE',
      url: `/courses/${courseId}?deletion_type=hard`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(deleteCourseSuccess(courseId));
        } else {
          dispatch(deleteCourseFailure('Deleting course failed'));
          openNotification({
            type: 'error',
            title: 'Deleting Course Failed',
            description: 'Deleting course failed',
          });
        }
      })
      .catch((error) => {
        dispatch(deleteCourseFailure('An error occurred while deleting the course'));
        openNotification({
          type: 'error',
          title: 'Deleting Course Error',
          description: `An error occurred while deleting the course: ${error.message}`,
        });
      });
  };
};

export const searchCourses = (query) => {
  return (dispatch) => {
    dispatch(searchCoursesRequest());
    return request({
      method: 'GET',
      url: `/courses/?name=${query}`,
    })
      .then((response) => {
        if (response?.data) {
          dispatch(searchCoursesSuccess(response.data));
        } else {
          dispatch(searchCoursesFailure('No courses found matching your criteria'));
        }
      })
      .catch((error) => {
        dispatch(searchCoursesFailure('An error occurred while searching for courses', error));
      });
  };
};
