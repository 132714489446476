export const MIME_TYPES = {
  formData: 'multipart/form-data',
  json: 'application/json',
  zip: 'application/zip',
};

// Adding new constant for attendance labels and their colors
export const ATTENDANCE_LABELS = {
  "Attended": { label: 'Attended', color: '#91CF50' }, // Green
  "Failed to Attend": { label: 'Failed to Attend', color: '#FF0000' }, // Red
  "Workshop": { label: 'Workshop', color: '#FEC000' }, // Yellow
  "Exam": { label: 'Exam', color: '#FFFF00' }, // Blue
  "One to One": { label: 'One to One', color: '#00AFF0' }, // Orange
  "Progress Review / Exit / Next Qual": { label: 'Progress Review / Exit / Next Qual', color: '#B1A0C7' }, // Purple
  "Induction": { label: 'Induction', color: '#FABE8F' }, // Dark Green
  "SLC Exam (2 hours)": { label: 'SLC Exam (2 hours)', color: '#00AF50' }, // Pink
  "Enrolments": { label: 'Enrolments', color: '#70309F' }, // Dark Gray
  "Learning": { label: 'Learning', color: '#D9D9D9' }, // Light Gray
};
