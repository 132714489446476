import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

const EventComponent = ({ event }) => {
  const formattedNames = event.extendedProps.studentNames.join(', ');
  const { color } = event.extendedProps; // Get the color from extendedProps
  console.log("event", event);

  return (
    <Tooltip
      title={(
        <div>
          <div><strong>Session:</strong> {event.label}</div>
          <div><strong>Capacity:</strong> {event.extendedProps.capacity}</div>
          <div><strong>Course:</strong> {event.extendedProps.courseName}</div>
          <div><strong>Students:</strong> {formattedNames}</div>
        </div>
      )}
    >
      <div
        title={event.label}
        style={{
          width: '100%', // Make it full width
          height: '100%', // Make it full height for better visibility
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          backgroundColor: color || '#f0f0f0', // Default background color if no color is defined
          color: '#000', // Use black for contrast
          fontWeight: 500,
          padding: '10px 10px', // Adjusted padding for better spacing
          borderRadius: '4px', // Add border radius for rounded corners
          border: '1px solid rgba(0, 0, 0, 0.1)', // Optional subtle border for better definition
          display: 'flex', // Use flexbox for alignment
          alignItems: 'center', // Center the content vertically
          justifyContent: 'center', // Center the content horizontally
        }}
      >
        {event.label}
      </div>
    </Tooltip>
  );
};

EventComponent.propTypes = {
  event: PropTypes.shape({
    label: PropTypes.string.isRequired,
    extendedProps: PropTypes.shape({
      capacity: PropTypes.number.isRequired,
      studentNames: PropTypes.arrayOf(PropTypes.string).isRequired,
      courseName: PropTypes.string,
      campus_id: PropTypes.string,
      color: PropTypes.string.isRequired, // Ensure color is required
    }).isRequired,
  }).isRequired,
};

export default EventComponent;
