import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Row, Col, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import AddSessionForm from './Form/AddSessionForm';
import { fetchSessions, createSession, updateSession, deleteSession } from './sessions.api';
import 'tippy.js/dist/tippy.css';
import EventContextMenu from './EventContextMenu';
import { useNavigate } from 'react-router-dom';
import { fetchCampuses } from '../Campuses/campuses.api';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './AdminSessions.css'; // Import the custom CSS
import EventComponent from './EventComponent';
import { setSelectedCampus } from '../Dashboard/dashboard.actions'
import { ATTENDANCE_LABELS } from '../../core/constants'

const localizer = momentLocalizer(moment);

const AdminSessions = () => {
  const dispatch = useDispatch();
  const { sessions } = useSelector((state) => state.sessions);
  const { campuses } = useSelector((state) => state.campuses);
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [form] = Form.useForm();
  const [, setSelectedSlot] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const navigate = useNavigate();
  const role = localStorage.getItem('role');
  const [showPCsAsResources, setShowPCsAsResources] = useState(true);
  const { selectedCampus } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (role === 'admin') {
      dispatch(fetchSessions());
      dispatch(fetchCampuses(localStorage.getItem('adminId')));
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dispatch, role]);

  useEffect(() => {
    if (campuses.length > 0) {
      if (selectedCampus === null) {
        dispatch(setSelectedCampus(campuses[0].id));
      } else {
        const currentCampus = campuses.find((campus) => campus.id === selectedCampus);
        if (!currentCampus) {
          dispatch(setSelectedCampus(campuses[0].id));
        }
      }
    }
  }, [campuses]);

  const handleClickOutside = () => {
    setContextMenuVisible(false);
  };

  const handleCampusChange = (value) => {
    dispatch(setSelectedCampus(value));
  };

  const handleAddSession = async (values) => {
    if(values?.session_type === 'other'){
      values = {
        ...values,
        students: values.student_ids.map(item => ({
          student_id: item
        })),
      }
    }
    const sessionData = {
      ...values,
      start_datetime: values.start_datetime.format(),
      end_datetime: values.end_datetime.format(),
    };
    dispatch(createSession(sessionData)).then(() => {
      setModalVisible(false);
      form.resetFields();
      dispatch(fetchSessions());
    });
  };

  const handleUpdateSession = async (values) => {
    const sessionId = selectedEvent.id.split('-')[0];
    const sessionData = {
      ...values,
      id: sessionId,
      start_datetime: values.start_datetime.format(),
      end_datetime: values.end_datetime.format(),
    };
    dispatch(updateSession(sessionData)).then(() => {
      setEditModalVisible(false);
      form.resetFields();
      dispatch(fetchSessions());
    });
  };

  const handleDeleteSession = async (deletionType) => {
    dispatch(deleteSession({ id: selectedEvent.sessionId, deletionType })).then(() => {
      setEditModalVisible(false);
      setDeleteModalVisible(false);
      setArchiveModalVisible(false);
      dispatch(fetchSessions());
    });
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedSlot(slotInfo);
    form.setFieldsValue({
      start_datetime: moment(slotInfo.start),
      end_datetime: moment(slotInfo.end),
    });
    setModalVisible(true);
  };

  const handleSelectEvent = (event, e) => {
    setSelectedEvent(event);
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    setContextMenuVisible(true);
  };

  const handleEdit = () => {
    const selectedSessionData = JSON.parse(selectedEvent?.session);
    setSelectedCampus(selectedSessionData.campusId); // Set the campus based on the session
    form.setFieldsValue({
      name: selectedEvent.name,
      label: selectedEvent.label,
      start_datetime: moment(selectedEvent.start),
      end_datetime: moment(selectedEvent.end),
      capacity: selectedEvent.extendedProps.capacity,
      description: selectedEvent.extendedProps.description,
      session: JSON.parse(selectedEvent.session),
      student_ids: selectedSessionData.attendance.map((item) => item.student.id),
      campus_id: selectedSessionData.campusId, // Set the campus ID here
      session_type: selectedSessionData.sessionType,
      students: selectedSessionData.attendance.map((item) => ({
        pc_id: item.pc.id,
        student_id: item.student.id,
      })),
      course_id: selectedSessionData.courseId,
    });
    setEditModalVisible(true);
    setContextMenuVisible(false);
  };

  const handleDelete = () => {
    setDeleteModalVisible(true);
    setContextMenuVisible(false);
  };

  const handleArchive = () => {
    setArchiveModalVisible(true);
    setContextMenuVisible(false);
  };

  const handleViewAttendance = () => {
    navigate(`/sessions/${selectedEvent.sessionId}/attendance`);
    setContextMenuVisible(false);
  };

  const filteredPCs = campuses.find(campus => campus.id === selectedCampus)?.pcs || [];

  const bigCalendarEvents = showPCsAsResources
    ? sessions
      .filter(session => session.sessionType === 'pc')
      .flatMap((filteredSession) =>
        filteredSession.attendance?.map((item) => ({
          id: `${filteredSession.id}-${item.pcId}`, // Ensure each event has a unique ID
          name: `${filteredSession.name}`,
          label: filteredSession?.label && `${filteredSession.label.split(' (')[0]}`,
          description: `${filteredSession.description}`,
          sessionId: `${filteredSession.id}`,
          session: JSON.stringify(filteredSession),
          start: new Date(filteredSession.startDatetime),
          end: new Date(filteredSession.endDatetime),
          resourceId: item.pc.id,
          extendedProps: {
            capacity: filteredSession.capacity,
            studentNames: [item.student.name],
            courseName: filteredSession.courseName || "",
            campus_id: filteredSession.campusId,
            color: ATTENDANCE_LABELS[filteredSession.label]?.color || '#000000', // Get color from ATTENDANCE_LABELS
          },
        }))
      )
    : sessions
      .filter(session => session.sessionType === 'other')
      .map((session) => ({
        id: session.id,
        name: `${session.name}`,
        label: session?.label && `${session.label.split(' (')[0]}`,
        description: session.description,
        session: JSON.stringify(session),
        sessionId: `${session.id}`,
        start: new Date(session.startDatetime),
        end: new Date(session.endDatetime),
        extendedProps: {
          capacity: session.capacity,
          studentNames: session.attendance.map(item => item.student.name),
          courseName: session.courseName || "",
          campus_id: session.campusId,
          color: ATTENDANCE_LABELS[session.label]?.color || '#000000', // Get color from ATTENDANCE_LABELS
        },
      }));

  console.log("bigCalendarEvents",bigCalendarEvents)

  const resources = showPCsAsResources
    ? filteredPCs.map((pc) => ({
      id: pc.id,
      title: pc.name,
    }))
    : [];

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 20 }}>
        <h2 style={{ margin: 0 }}>Sessions</h2>
        {campuses && (
          <Select
            placeholder="Select Campus"
            defaultValue={campuses?.length > 0 && campuses[0]?.id}
            onChange={handleCampusChange}
            style={{ width: 200 }}
            value={selectedCampus}
          >
            {campuses.map((campus) => (
              <Select.Option key={campus.id} value={campus.id}>
                {campus.name}
              </Select.Option>
            ))}
          </Select>
        )}
        <div style={{ marginLeft: 'auto' }}>
          <span>Show PCs as Resources: </span>
          <Switch checked={showPCsAsResources} onChange={setShowPCsAsResources} />
        </div>
      </div>

      <div className="custom-resource">
        <Calendar
          localizer={localizer}
          events={bigCalendarEvents || []}
          startAccessor="start"
          endAccessor="end"
          resources={resources?.length > 0 && resources}
          resourceIdAccessor="id"
          resourceTitleAccessor="title"
          selectable
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          defaultView="day"
          views={['day', 'agenda']}
          step={30}
          timeslots={1}
          min={new Date(2024, 0, 1, 9, 0, 0)} // Set the minimum time to 9 AM
          max={new Date(2024, 0, 1, 18, 0, 0)} // Set the maximum time to 5 PM
          style={{ height: '100%' }}
          components={{
            event: EventComponent,
          }}
          formats={{
            timeGutterFormat: 'HH:mm', // 24-hour format for time
            dayFormat: 'dddd', // Display full day name
            agendaTimeFormat: { // Agenda time format in 24-hour
              start: 'HH:mm', // Starting time format
              end: 'HH:mm', // Ending time format
            },
          }}
          onDoubleClickEvent={handleSelectEvent}
        />
      </div>

      {role === 'admin' && (
        <>
          <Modal
            title="Add Session"
            visible={modalVisible}
            onCancel={() => {
              setModalVisible(false);
              form.resetFields();
            }}
            footer={null}
          >
            <AddSessionForm
              form={form}
              setSelectedCampus={setSelectedCampus}
              onFinish={handleAddSession}
              modalVisible={modalVisible}
              existingPcs={[]}
              selectedCampus={selectedCampus} // Pass the selected campus
            />
            <Button type="primary" onClick={() => form.submit()}>
              Save Session
            </Button>
          </Modal>

          <Modal
            title="Edit Session"
            visible={editModalVisible}
            onCancel={() => {
              setEditModalVisible(false);
              form.resetFields();
            }}
            footer={null}
          >
            {editModalVisible ? <AddSessionForm
              form={form}
              existingPcs={selectedEvent && JSON.parse(selectedEvent?.session)?.attendance?.map(item => ({
                id: item.pc.id,
                name: item.pc.name,
                student: item.student.id,
              })) || []}
              onFinish={handleUpdateSession}
              selectedCampus={selectedCampus} // Pass the selected campus
            /> : ""}
            <Row gutter={8} justify="end">
              <Col>
                <Button type="primary" onClick={() => form.submit()}>
                  Update Session
                </Button>
              </Col>
              <Col>
                <Button
                  type="danger"
                  style={{ backgroundColor: 'red', color: 'white' }}
                  onClick={() => setDeleteModalVisible(true)}
                >
                  Delete Session
                </Button>
              </Col>
            </Row>
          </Modal>

          <Modal
            title="Delete Session"
            visible={deleteModalVisible}
            onCancel={() => setDeleteModalVisible(false)}
            onOk={() => handleDeleteSession('hard')}
          >
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>Are you sure you want to delete the session "{selectedEvent?.title}"?</p>
          </Modal>

          <Modal
            title="Archive Session"
            visible={archiveModalVisible}
            onCancel={() => setArchiveModalVisible(false)}
            onOk={() => handleDeleteSession('soft')}
          >
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>Are you sure you want to archive the session "{selectedEvent?.title}"?</p>
          </Modal>

          <EventContextMenu
            position={contextMenuPosition}
            visible={contextMenuVisible}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onArchive={handleArchive}
            onViewAttendance={handleViewAttendance}
          />
        </>
      )}
    </div>
  );
};

export default AdminSessions;
