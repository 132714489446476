import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCampuses } from '../../Campuses/campuses.api';

const { Option } = Select;

const AddAdminForm = ({ onSubmit, form }) => {
  const dispatch = useDispatch();
  const { campuses, loading } = useSelector((state) => state.campuses);
  const [campusOptions, setCampusOptions] = useState([]);

  useEffect(() => {
    const adminId = localStorage.getItem('adminId');
    if (adminId) {
      dispatch(fetchCampuses(adminId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (campuses) {
      setCampusOptions(campuses);
    }
  }, [campuses]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => {
        values.super_admin = values.role === 'super_admin';
        onSubmit(values);
      }}
    >
      <Form.Item
        name="first_name"
        label="First Name"
        rules={[{ required: true, message: 'Please enter first name' }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name="last_name"
        label="Last Name"
        rules={[{ required: true, message: 'Please enter last name' }]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please enter email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'Please enter your password' },
          { min: 6, message: 'Password must be at least 6 characters' },
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item
        name="role"
        label="Role"
        rules={[{ required: true, message: 'Please select a role' }]}
      >
        <Select placeholder="Select Role">
          <Option value="super_admin">Super Admin</Option>
          <Option value="admin">Admin</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="campus_ids"
        label="Campuses"
        rules={[{ required: true, message: 'Please select at least one campus' }]}
      >
        <Select
          mode="multiple"
          placeholder="Select Campuses"
          loading={loading}
        >
          {campusOptions.map((campus) => (
            <Option key={campus.id} value={campus.id}>
              {campus.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

AddAdminForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default AddAdminForm;
