import React, { useEffect } from 'react';
import { Form, Input, Button, Select } from 'antd';
import PropTypes from 'prop-types';
import { fetchCampuses } from '../../Campuses/campuses.api';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

const AddStudentForm = ({ onSubmit, form }) => {
  const dispatch = useDispatch();
  const adminId = localStorage.getItem('adminId');

  const { campuses } = useSelector((state) => state.campuses);

  useEffect(() => {
    dispatch(fetchCampuses(adminId));
  }, [dispatch]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="first_name"
        label="First Name"
        rules={[{ required: true, message: 'Please enter first name' }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name="last_name"
        label="Last Name"
        rules={[{ required: true, message: 'Please enter last name' }]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please enter email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="contact_number"
        label="Contact Number"
        rules={[{ required: true, message: 'Please enter contact number' }]}
      >
        <Input type="number" placeholder="Contact Number" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'Please enter your password' },
          { min: 6, message: 'Password must be at least 6 characters' },
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item
        name="campus_id"
        label="Select Campus"
        rules={[{ required: true, message: 'Please select a campus' }]}
      >
        <Select placeholder="Select a campus">
          {campuses?.length > 0 && campuses.map((campus) => (
            <Option key={campus.id} value={campus.id}>
              {campus.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

AddStudentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  fetchCampuses: PropTypes.func.isRequired,
};

export default AddStudentForm;
