import React, { useEffect } from 'react';
import { Table, Spin, Alert, Button, Space, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAttendances, deleteAttendance } from './attendance.api';
import { useParams } from 'react-router-dom'

const Attendance = () => {
  const dispatch = useDispatch();
  const { loading, attendances, error } = useSelector((state) => state.attendances);
  const { session_id } = useParams()

  console.log('sessionId', session_id)
  useEffect(() => {
    dispatch(fetchAttendances(session_id));
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteAttendance(id)).then(() => {
      dispatch(fetchAttendances(session_id));
    });
  };

  const columns = [
    { title: 'Student Name', dataIndex: 'student', key: 'student', render: (student) => (student.name) },
    { title: 'Campus Name', dataIndex: 'campus', key: 'campus', render: (campus) => (campus.name) },
    { title: 'Check-in', dataIndex: 'checkinDatetime', key: 'checkinDatetime', render: (text) => text ? new Date(text).toLocaleString() : '-' },
    { title: 'Check-out', dataIndex: 'checkoutDatetime', key: 'checkoutDatetime', render: (text) => text ? new Date(text).toLocaleString() : '-'},
    {
      title: 'Status',
      dataIndex: 'statuses',
      key: 'statuses',
      render: (statuses) => {
        return statuses.map(statusObj => {
          let color = 'blue';
          switch (statusObj.status) {
            case 'LEFT_EARLY':
              color = 'volcano';
              break;
            case 'ARRIVE_LATE':
              color = 'orange';
              break;
            case 'PRESENT':
              color = 'green';
              break;
            case 'ABSENT':
              color = 'red';
              break;
            case 'INFORMED_ABSENT':
              color = 'gold';
              break;
            case 'CANCELED_SESSION':
              color = 'gray';
              break;
            case 'PENDING':
              color = 'purple';
              break;
            default:
              color = 'blue';
          }
          return <Tag color={color} key={statusObj.id}>{statusObj.status}</Tag>;
        });
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="danger"
            style={{ backgroundColor: 'red', color: 'white' }}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  if (loading) {
    return <Spin tip="Loading attendances..." />;
  }

  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2>Attendances</h2>
      </div>
      <Table
        columns={columns}
        dataSource={attendances}
        rowKey="id"
        locale={{ emptyText: 'No data' }}
      />
    </div>
  );
};

export default Attendance;
