import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  HomeOutlined,
  LogoutOutlined,
  UserOutlined,
  TeamOutlined,
  ClockCircleOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { logout } from '../components/Auth/auth.api';
import ctplogo from '../../src/assets/images/ctplogo.png';
const { Sider } = Layout;
import _ from 'lodash'; // Import lodash

const SidebarMenu = ({ collapsed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState('/dashboard');
  const role = localStorage.getItem('role'); // Get the user role from localStorage
  const studentId = localStorage.getItem('studentId');

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('role'); // Remove the role from localStorage
    localStorage.removeItem('studentId'); // Remove the student ID from localStorage
    localStorage.removeItem('adminId');
    navigate('/login');
  };

  const adminString = localStorage.getItem('admin');
  const superAdmin = localStorage.getItem('superAdmin');
  const admin = adminString ? JSON.parse(adminString) : null;

  const studentString = localStorage.getItem('student');
  const student = studentString ? JSON.parse(studentString) : null;

  return (
    <Sider
      width={collapsed ? 80 : 256}
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        backgroundColor: '#004990',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: collapsed ? 'center' : 'start',
            alignItems: 'center',
            padding: collapsed ? '0' : '26px',
          }}
        >
          <img
            src={ctplogo}
            alt="CTPortal Logo"
            style={{ width: collapsed ? '40%' : '30%', marginRight: collapsed ? '0' : '10px' }}
          />
          {!collapsed && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <h3 style={{ color: 'white', fontSize: '20px' }}>CTPortal</h3>
              <span style={{ color: '#FFDD57', fontWeight: 'bold', fontSize: '12px' }}>
          Beta Version
        </span>
            </div>
          )}
        </div>
        <Menu
          selectedKeys={[selectedKey]}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          style={{ backgroundColor: '#004990', marginTop: 10 }}
        >
          {role === 'admin' && (
            <>
              <Menu.Item
                style={{
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  color: 'white',
                }}
                key="/dashboard"
                icon={<HomeOutlined />}
              >
                <Link to="/dashboard" style={{ color: 'white' }}>
                  Dashboard
                </Link>
              </Menu.Item>
              <Menu.Item
                style={{
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  color: 'white',
                }}
                key="/campuses"
                icon={<TeamOutlined />}
              >
                <Link style={{ color: 'white' }} to="/campuses">
                  Campus
                </Link>
              </Menu.Item>
              <Menu.Item
                style={{
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  color: 'white',
                }}
                key="/courses"
                icon={<TeamOutlined />}
              >
                <Link style={{ color: 'white' }} to="/courses">
                  Courses
                </Link>
              </Menu.Item>
              <Menu.Item
                style={{
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  color: 'white',
                }}
                key="/sessions"
                icon={<ClockCircleOutlined />}
              >
                <Link style={{ color: 'white' }} to="/sessions">
                  Sessions
                </Link>
              </Menu.Item>
              <Menu.Item
                style={{
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  color: 'white',
                }}
                key="/reports"
                icon={<BarChartOutlined />}
              >
                <Link style={{ color: 'white' }} to="/reports">
                  Reports
                </Link>
              </Menu.Item>
              <Menu.Item
                style={{
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  color: 'white',
                }}
                key="/students"
                icon={<UserOutlined />}
              >
                <Link style={{ color: 'white' }} to="/students">
                  Students
                </Link>
              </Menu.Item>
              {superAdmin === 'true' &&
                <Menu.Item
                  style={{
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    color: 'white',
                  }}
                  key="/admins"
                  icon={<UserOutlined />}
                >
                  <Link style={{ color: 'white' }} to="/admins">
                    Admins
                  </Link>
                </Menu.Item>}
              <Menu.Item
                style={{
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  color: '#FF4D4F',
                }}
                key="logout"
                icon={<LogoutOutlined />}
                onClick={handleLogout}
              >
                <span style={{ color: '#FF4D4F' }}>Logout</span>
              </Menu.Item>
            </>
          )}
          {role === 'student' && studentId && (
            <>
              <Menu.Item
                style={{
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  color: 'white',
                }}
                key={`/sessions/${studentId}`}
                icon={<ClockCircleOutlined />}
              >
                <Link style={{ color: 'white' }} to={`/sessions/${studentId}`}>
                  Sessions
                </Link>
              </Menu.Item>
              <Menu.Item
                style={{
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  color: 'white',
                }}
                key={`/reports/${studentId}`}
                icon={<BarChartOutlined />}
              >
                <Link style={{ color: 'white' }} to={`/reports/${studentId}`}>
                  Reports
                </Link>
              </Menu.Item>
              <Menu.Item
                style={{
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  color: '#FF4D4F',
                }}
                key="logout"
                icon={<LogoutOutlined />}
                onClick={handleLogout}
              >
                <span style={{ color: '#FF4D4F' }}>Logout</span>
              </Menu.Item>
            </>
          )}
        </Menu>
      </div>
      <div style={{ padding: 20, textAlign: 'center', color: 'white' }}>
        <div
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '8px',
            paddingTop: '50px',
            paddingRight: '20px',
            paddingLeft: '20px',
            height: 150,
            marginTop: admin ? 200 : 350,
            textAlign: 'center',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>
              {admin &&
                <>
                  <div style={{ fontWeight: 700 }}>{_.startCase(admin?.firstName)} {_.startCase(admin?.lastName)}</div>
                  <br />
                  <div>{admin?.email}</div>
                </>
              }
              {student &&
                <>
                  <div style={{fontWeight: 700}}>{_.startCase(student?.firstName)} {_.startCase(student?.lastName)}</div>
                  <br />
                  <div>{student?.email}</div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </Sider>
  );
};

SidebarMenu.propTypes = {
  collapsed: PropTypes.bool.isRequired,
};

export default SidebarMenu;
