import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Spin, Button, Row, Col } from 'antd';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchStudents, searchStudents } from '../../Students/students.api';
import { fetchCourses, searchCourses } from '../../Courses/courses.api'; // Import searchCourses
import { fetchAvailablePCs } from '../sessions.api';
import moment from 'moment';
import { ATTENDANCE_LABELS } from '../../../core/constants';

const { Option } = Select;

// eslint-disable-next-line react/prop-types
const AddSessionForm = ({ form, onFinish, selectedCampus, existingPcs }) => {
  const dispatch = useDispatch();
  const { campuses } = useSelector((state) => state.campuses);
  const { searchedStudents, students, loading: loadingStudents } = useSelector((state) => state.students);
  const { searchedCourses, courses, loading: loadingCourses } = useSelector((state) => state.courses); // Get searchedCourses
  const { loading: loadingPCs, availablePCs } = useSelector((state) => state.sessions);
  const [mergedPcs, setMergedPCs] = useState([]);
  const [pcStudentPairs, setPcStudentPairs] = useState([{ pc: undefined, student: undefined }]);
  const [studentQuery, setStudentQuery] = useState('');
  const [courseQuery, setCourseQuery] = useState(''); // Add courseQuery state

  useEffect(() => {
    dispatch(fetchStudents({ campusId: selectedCampus }));
    dispatch(fetchAvailablePCs(selectedCampus));

    // eslint-disable-next-line react/prop-types
    if (existingPcs?.length > 0) {
      // eslint-disable-next-line react/prop-types
      setPcStudentPairs(existingPcs.map(pc => ({
        pc: pc.id,
        student: pc.student,
      })));
    }
  }, []);

  useEffect(() => {
    if (availablePCs || existingPcs) {
      const uniquePCs = new Map([...availablePCs, ...existingPcs].map(pc => [pc.id, pc]));
      setMergedPCs(Array.from(uniquePCs.values()));
    }
  }, [availablePCs, existingPcs]);

  useEffect(() => {
    if (selectedCampus) {
      dispatch(fetchCourses(selectedCampus));
      dispatch(fetchAvailablePCs(selectedCampus));
    }
  }, [dispatch, selectedCampus]);

  const fetchPCs = () => {
    const { start_datetime, end_datetime, campus_id } = form.getFieldsValue();
    if (start_datetime && end_datetime && campus_id) {
      const formattedStartDatetime = moment(start_datetime).format();
      const formattedEndDatetime = moment(end_datetime).format();
      dispatch(fetchAvailablePCs(campus_id, formattedStartDatetime, formattedEndDatetime));
    }
  };

  useEffect(() => {
    // Fetch PCs only when necessary
    fetchPCs();
  }, [form.getFieldValue('start_datetime'), form.getFieldValue('end_datetime'), form.getFieldValue('campus_id')]);

  const handleAddPcStudentPair = () => {
    setPcStudentPairs([...pcStudentPairs, { pc: undefined, student: undefined }]);
  };

  const handleRemovePcStudentPair = (index) => {
    const pairs = [...pcStudentPairs];
    pairs.splice(index, 1);
    setPcStudentPairs(pairs);
  };

  const handleSearchStudents = (query) => {
    setStudentQuery(query);
    if (query) {
      dispatch(searchStudents(query));
    } else {
      dispatch(fetchStudents({ campusId: selectedCampus })); // Ensure students are fetched for the selected campus
    }
  };

  const handleSearchCourses = (query) => { // Create search function for courses
    setCourseQuery(query);
    if (query) {
      dispatch(searchCourses(query));
    } else {
      dispatch(fetchCourses(selectedCampus)); // Fetch courses for the selected campus
    }
  };

  const selectedPCs = pcStudentPairs.map(pair => pair.pc).filter(Boolean);
  const studentOptions = studentQuery ? searchedStudents : students;
  const courseOptions = courseQuery ? searchedCourses : courses; // Select the appropriate course options

  return (
    <Form form={form} onFinish={onFinish}>
      {/* Other form items remain unchanged */}
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please input the name of the session!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="label"
        label="Label and Color"
        rules={[{ required: true, message: 'Please select a label!' }]}
      >
        <Select placeholder="Select a label">
          {Object.entries(ATTENDANCE_LABELS).map(([key, { label, color }]) => (
            <Option key={key} value={label} style={{ backgroundColor: color, color: 'black', fontWeight: 500, marginTop: 5 }}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="campus_id"
        label="Campus"
        initialValue={selectedCampus}
        rules={[{ required: true, message: 'Please select a campus!' }]}
      >
        <Select value={selectedCampus} onChange={(value) => {
          // eslint-disable-next-line react/prop-types
          form.setFieldsValue({ campus_id: value });
          dispatch(fetchCourses(value));
          dispatch(fetchStudents({ campusId: value }));
          dispatch(fetchAvailablePCs(value));
        }}>
          {campuses.map(campus => (
            <Select.Option key={campus.id} value={campus.id}>
              {campus.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="start_datetime"
        label="Start Time"
        rules={[{ required: true, message: 'Please input the start time!' }]}
      >
        <Datetime onChange={() => fetchPCs()} />
      </Form.Item>
      <Form.Item
        name="end_datetime"
        label="End Time"
        rules={[{ required: true, message: 'Please input the end time!' }]}
      >
        <Datetime onChange={() => fetchPCs()} />
      </Form.Item>
      <Form.Item
        name="session_type"
        label="Session Type"
        rules={[{ required: true, message: 'Please select the session type!' }]}
      >
        <Select>
          <Option value="pc">PC</Option>
          <Option value="other">Other</Option>
        </Select>
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) => prevValues.session_type !== currentValues.session_type || prevValues.campus_id !== currentValues.campus_id}
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue('session_type') === 'pc' ? (
            <>
              {pcStudentPairs.map((pair, index) => (
                <Row key={index} gutter={16}>
                  <Col span={10}>
                    <Form.Item
                      name={['students', index, 'pc_id']}
                      label="PC"
                      rules={[{ required: true, message: 'Please select a PC!' }]}
                    >
                      {loadingPCs ? (
                        <Spin />
                      ) : (
                        <Select
                          value={pair.pc}
                          onChange={value => {
                            const newPairs = [...pcStudentPairs];
                            newPairs[index].pc = value;
                            setPcStudentPairs(newPairs);
                          }}
                        >
                          {mergedPcs
                            .filter(pc => !selectedPCs.includes(pc.id) || pc.id === pair.pc)
                            .map(pc => (
                              <Option key={pc.id} value={pc.id}>
                                {pc.name}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name={['students', index, 'student_id']}
                      label="Student"
                      rules={[{ required: true, message: 'Please select a student!' }]}
                    >
                      <Select
                        showSearch
                        value={pair.student}
                        onSearch={handleSearchStudents}
                        onChange={value => {
                          const newPairs = [...pcStudentPairs];
                          newPairs[index].student = value;
                          setPcStudentPairs(newPairs);
                        }}
                        filterOption={false}
                        notFoundContent={loadingStudents ? <Spin size="small" /> : null}
                      >
                        {studentOptions.map(student => (
                          <Option key={student.id} value={student.id}>
                            {student.firstName} {student?.lastName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    {index > 0 && (
                      <Button
                        type="danger"
                        onClick={() => handleRemovePcStudentPair(index)}
                        style={{ marginTop: '29px' }}
                      >
                        Remove
                      </Button>
                    )}
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={handleAddPcStudentPair}>
                  Add PC with Student
                </Button>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                name="student_ids"
                label="Students"
                rules={[{ required: true, message: 'Please select at least one student!' }]}
              >
                <Select
                  mode="multiple"
                  showSearch
                  onSearch={handleSearchStudents}
                  filterOption={false}
                  notFoundContent={loadingStudents ? <Spin size="small" /> : null}
                >
                  {studentOptions.map(student => (
                    <Option key={student.id} value={student.id}>
                      {student.firstName} {student?.lastName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )
        }
      </Form.Item>
      <Form.Item
        name="course_id"
        label="Course"
        rules={[{ required: true, message: 'Please select a course!' }]} // Add validation if required
      >
        <Select
          showSearch
          onSearch={handleSearchCourses}
          filterOption={false}
          notFoundContent={loadingCourses ? <Spin size="small" /> : null}
        >
          {courseOptions.map(course => (
            <Option key={course.id} value={course.id}>
              {course.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="capacity"
        label="Capacity"
        rules={[{ required: true, message: 'Please input the capacity!' }]}
      >
        <Input type="number" />
      </Form.Item>
    </Form>
  );
};

AddSessionForm.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  onFinish: PropTypes.func.isRequired,
  selectedCampus: PropTypes.string.isRequired,
};

export default AddSessionForm;
