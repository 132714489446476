import React, { useEffect } from 'react';
import { Row, Col, Card, Statistic, Progress, Spin, Alert, Select } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { fetchDashboardStats } from './dashboard.api';
import { fetchCampuses } from '../Campuses/campuses.api';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCampus } from './dashboard.actions'; // Import the action

const { Option } = Select;

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = () => {
  const dispatch = useDispatch();
  const { loading, stats, error, selectedCampus } = useSelector((state) => state.dashboard);
  const { campuses, loading: campusesLoading, error: campusesError } = useSelector((state) => state.campuses);

  useEffect(() => {
    const adminId = localStorage.getItem('adminId');
    adminId && dispatch(fetchCampuses(adminId));
  }, [dispatch]);

  useEffect(() => {
    if (campuses.length > 0 && selectedCampus === null) {
      const firstCampus = campuses[0];
      dispatch(setSelectedCampus(firstCampus.id));
      dispatch(fetchDashboardStats(firstCampus.id));
    }
  }, [campuses, dispatch]);

  const handleCampusChange = (campusId) => {
    dispatch(setSelectedCampus(campusId)); // Update selected campus in Redux
    dispatch(fetchDashboardStats(campusId));
  };

  if (campusesLoading) {
    return <Spin tip="Loading campuses..." />;
  }

  if (campusesError) {
    return (
      <Alert message="Error" description={campusesError} type="error" showIcon />
    );
  }

  if (loading) {
    return <Spin tip="Loading dashboard stats..." />;
  }

  if (error) {
    return (
      <Alert message="Error" description={error.message} type="error" showIcon />
    );
  }

  const dailyAttendancePercentage = stats?.day.overallPresentPercentage.toFixed(2);
  const weeklyAttendancePercentage = stats?.week.overallPresentPercentage.toFixed(2);

  const attendanceComparisonData = {
    labels: stats?.month?.days.map(day => new Date(day.date).toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short'
    })),
    datasets: [
      {
        label: 'Monthly Attendance %',
        data: stats?.month?.days.map(day => parseFloat(day.overallPresentPercentage.toFixed(2))),
        fill: false,
        borderColor: '#1890ff',
        tension: 0.1,
      },
    ],
  };

  const weeklyAttendanceData = {
    labels: stats?.week?.days.map(day => new Date(day.date).toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short'
    })),
    datasets: [
      {
        label: 'Weekly Attendance %',
        data: stats?.week?.days.map(day => parseFloat(day.overallPresentPercentage.toFixed(2))),
        backgroundColor: '#1890ff',
      },
    ],
  };

  const overallStatusCount = stats?.day.overallStatusCount;

  return (
    <div style={{ padding: 24 }}>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Select
            value={selectedCampus}
            onChange={handleCampusChange}
            style={{ width: 200 }}
          >
            {campuses.map(campus => (
              <Option key={campus.id} value={campus.id}>
                {campus.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Statistic title="Pending Status" value={overallStatusCount?.pending} />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="On Time"
              value={overallStatusCount?.present}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Absent"
              value={overallStatusCount?.absent}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title="Late Arrival" value={overallStatusCount?.arriveLate} />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 16 }}>
        <Col span={6}>
          <Card>
            <Statistic title="Early Departures" value={overallStatusCount?.leftEarly} />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title="Informed Absents" value={overallStatusCount?.informedAbsent} />
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Daily Attendance" >
            <Progress type="dashboard" percent={dailyAttendancePercentage} />
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Weekly Attendance" >
            <Progress type="dashboard" percent={weeklyAttendancePercentage} />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: 16 }}>
        <Col span={12}>
          <Card title="Attendance Comparison Chart">
            <Line data={attendanceComparisonData} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Weekly Attendance">
            <Bar data={weeklyAttendanceData} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
