export const FETCH_STATS_REQUEST = 'FETCH_STATS_REQUEST'
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS'
export const FETCH_STATS_FAILURE = 'FETCH_STATS_FAILURE'

export const SET_SELECTED_CAMPUS = 'SET_SELECTED_CAMPUS';

export const setSelectedCampus = (campusId) => ({
  type: SET_SELECTED_CAMPUS,
  payload: campusId,
});

export const fetchStatsRequest = () => ({
  type: FETCH_STATS_REQUEST,
})

export const fetchStatsSuccess = (stats) => ({
  type: FETCH_STATS_SUCCESS,
  payload: stats,
})

export const fetchStatsFailure = (error) => ({
  type: FETCH_STATS_FAILURE,
  payload: error,
})
